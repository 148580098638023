const main = () => {
    const $ = (...args) => document.querySelector(...args);
    //command prompt
    const blinky = $('#blinky');
    setInterval(function(){
        blinky.classList.toggle('hidden');
    }, 1000);

    $("#cursor").addEventListener('keyup', (event) => {
        $("#consoleDisplay").innerText =  event.target.value;
        $("#cursor").focus();
    });
    $("#main").addEventListener('click', (event) => {
        $("#cursor").focus();
    });

    const conToArr = (string) => {
        return string.split("");
    }
    /* */


    //console like return maker
    function makeCon(con, i){
        setTimeout(function(){
            $("#main p span#console").innerHTML += con;
            $("#cursor").focus();
        }, i);

    }

    function conInit(content){
        var arr = conToArr(content);
        var i = 10;
        for (const n in arr){
            makeCon(arr[n], i);
            i += 10;
        }

        setTimeout(() => {
            makeCon('<br/><br/>');
        }, i);
    }

    var content = '************************    ESKALACJA 2.0 BASIC      ************************          UNLIMITED CREATIVITY AND SKILL ****** SOME RESOURCES ALWAYS AVAILABLE  READY.';

    conInit(content);

    function sendReq({ req }){
        console.log(req);
        var data = {
            'help': 'Following commands are available: contact, about',
            'contact': 'To contact us, simply send an email to biuro@eskalacja.com',
            'about': 'We do software development. Web is out "thing" but we also do backend stuff (except going deep into DevOps topics). You can call it full stack',
        };
        var fallback = 'There nothing we can do about it. Try ask for help by typing \'help\'.';


        if (data[req]) {
            return conInit(data[req]);
        }

        return conInit(fallback);
    }

    // if enter pressed send the message
    document.addEventListener('keyup', (e) => {

        const code= (e.keyCode ? e.keyCode : e.which);
        if (code === 13) {
            var req = {req : $("#cursor").value};
            sendReq(req);
            e.preventDefault();
            $("#consoleDisplay").innerText = '';
            $("#cursor").value = '';
        }
    });
}

main();
